export const DISCOUNT_DELIVERY_FROM_PRICE = 100;
export const SEND_COST = 8.95;
export const DISCOUNT_SEND_COST = 5.95;
export const CURRENCY = "€ ";
export const CURRENCY_CODE = "EUR";
export const DELIVERY_OPTIONS = {
  PICKUP: "pickup",
  SEND: "send",
};

export const PAYMENT_METHODS = {
  CREDITCARD: "creditcard",
  IDEAL: "ideal",
  BANCONTACT: "bancontact",
};

export const ADDRESS_TYPES = {
  DELIVERY: "DELIVERY",
  INVOICE: "INVOICE",
};
export const PICKUP_TIME_FROM = "13:00";
export const PICKUP_TIME_UNTIL = "16:00";
export const PICKUP_TIME_FROM_SATURDAY = "11:00";
export const PICKUP_TIME_UNTIL_SATURDAY = "15:00";
export const DELIVERY_TIME_FROM = "17:00";
export const DELIVERY_TIME_UNTIL = "22:00";
export const DELIVERY_DATE_WARNING_DAYS = 28;
