import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, notification, Space } from "antd";
import { privacy } from "../../urls";
import { Link } from "react-router-dom";
import { initializeAnalytics } from "../../services/firebase";
import ReactPixel from "react-facebook-pixel";

const Context = React.createContext({ name: "Default" });

const CookieBanner = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const cookieConsent = localStorage.getItem("consentGranted");
  const [analyticsLoaded, setAnalyticsLoaded] = React.useState(false);

  useEffect(() => {
    if (!cookieConsent) {
      openNotification("bottomRight");
    } else if (cookieConsent === "true" && !analyticsLoaded) {
      setGtag();
      initializeAnalytics();
      initFbPixel();
      setAnalyticsLoaded(true);
    }
  }, [cookieConsent]);

  const contextValue = useMemo(() => ({ name: "Cookies" }), []);

  const openNotification = (placement) => {
    const btn = (
      <Space>
        <Link to={privacy}>
          <Button type="link" size="small">
            {t("read_more")}
          </Button>
        </Link>
        <Button size="small" onClick={handleDecline}>
          {t("cookiebar_decline")}
        </Button>
        <Button type="primary" size="small" onClick={handleAccept}>
          {t("cookiebar_accept")}
        </Button>
      </Space>
    );
    api.open({
      message: t("cookiebar_title"),
      description: t("cookiebar_description"),
      duration: 0,
      closeIcon: null,
      placement,
      btn,
    });
  };

  const handleAccept = () => {
    setGtag();
    initFbPixel();
    localStorage.setItem("consentGranted", "true");
    initializeAnalytics();
    setAnalyticsLoaded(true);
    api.destroy();
  };

  const handleDecline = () => {
    api.destroy();
    localStorage.setItem("consentGranted", "false");
  };

  const setGtag = () => {
    function gtag() {
      window?.dataLayer.push(arguments);
    }

    gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: "granted",
    });

    // Load gtag.js script.
    let gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src =
      "https://www.googletagmanager.com/gtag/js?id=AW-10810408880";

    let firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(gtagScript, firstScript);
  };

  const initFbPixel = () => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, options);
    ReactPixel.pageView(); // For tracking page view
  };

  return (
    <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
  );
};

export default CookieBanner;
