import { transparentize } from "./helperFn";

export const colors = {
  primary: "#579B3C",
  primary80: transparentize("#579B3C", 0.8),
  primaryDark: "#406205",
  backgroundDark: "#0b1620",
  secondary: "#579B3C",
  text: "#333333",
  textDarkMode: "#ffffff",
  btn: "#4B5A57",
  cta_btn: "#579B3C",
  cta_btn_text: "#fff",
  loyalty_primary: "#579b3c",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#0066ff",
  featuredTag: "#ffaa00",
  hamburger: "#406205",
  menu_items: "#222",
};

export const styles = {
  btn_border_radius: "5px",
  card_border_radius: "10px",
  bullet_point_icon: "💎  ",
  banner_padding_desktop: "5rem",
  banner_padding_mobile: "3rem",
};
