import PropTypes from "prop-types";
import React from "react";
import LanguageChanger from "./sub-components/LanguageChanger";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FeedbackWidget from "./sub-components/FeedbackWidget";

const HeaderTop = ({ borderStyle }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebaseReducer.auth);

  return (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <LanguageChanger />
      <div className="header-offer">
        <p>{t("order_before_slogan")}</p>
      </div>
      {auth.isLoaded && !auth.isEmpty && (
        <div className="header-top-item d-flex flex-row">
          <FeedbackWidget />
        </div>
      )}
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
