import urls from "../urls";
import { WEBSITE_NAME } from "./CompanyConstants";

// Breadcrumb names
const homepageUrl = process.env.REACT_APP_WEBSITE_URL;
export const BREADCRUMB_HOME = {
  name: WEBSITE_NAME + ".nl",
  url: homepageUrl,
};
export const BREADCRUMB_PRODUCTS = {
  name: "Producten",
  url: homepageUrl + urls.shop,
};
export const BREADCRUMB_ABOUT = {
  name: "Over Ons",
  url: homepageUrl + urls.about,
};
export const BREADCRUMB_CHECKOUT = {
  name: "Afrekenen",
  url: homepageUrl + urls.checkout,
};
export const BREADCRUMB_CONTACT = {
  name: "Contact",
  url: homepageUrl + urls.contact,
};
export const BREADCRUMB_NOT_FOUND = {
  name: "Niet Gevonden",
  url: homepageUrl + urls.not_found,
};
export const BREADCRUMB_BLOG = {
  name: "Blog",
  url: homepageUrl + urls.blog,
};
export const BREADCRUMB_PRIVACY = {
  name: "Privacy Beleid",
  url: homepageUrl + urls.privacy,
};
export const BREADCRUMB_DELIVERY_RETURNS = {
  name: "Verzenden & retourneren",
  url: homepageUrl + urls.delivery_returns,
};
export const BREADCRUMB_FAQ = {
  name: "Veelgestelde Vragen",
  url: homepageUrl + urls.faq,
};
export const BREADCRUMB_HALAL_SLAGER = {
  name: "Dé Halal slager van Nederland",
  url: homepageUrl + urls.halal_slager,
};
export const BREADCRUMB_HALAL_CERTIFICATES = {
  name: "Halal Certificaten",
  url: homepageUrl + urls.certificates,
};
export const BREADCRUMB_RECIPES = {
  name: "Recepten",
  url: homepageUrl + urls.recipes,
};
export const BREADCRUMB_WINKEL = {
  name: "Winkel",
  url: homepageUrl + urls.winkel,
};
export const BREADCRUMB_GRASGEVOERD_VLEES = {
  name: "Grasgevoerd halal vlees",
  url: homepageUrl + urls.grasgevoerd,
};
