import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import loyaltyProgramData from "../../data/loyalty-program/loyalty-program-data.json";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { find } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { colors } from "../../utils/theme";
import { Button, message, notification, Popconfirm, Tooltip } from "antd";
import GiftIcon from "./GiftIcon";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  FIRESTORE_LOYALTY_CLAIMS_TABLE,
  FIRESTORE_LOYALTY_USERS_TABLE,
} from "../../constants/FirebaseConstants";
import { useFirestore } from "react-redux-firebase";
import { sendExceptionToSlack } from "../../services/api/exception";

const modalVariants = {
  visible: { translateX: 0 },
  hidden: { translateX: "calc(100% + 40px)" },
};

export default function LoyaltyProgramModal({ closeModal, active = true }) {
  const { t, i18n } = useTranslation();
  const DATA = loyaltyProgramData[i18n.language];
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const user = useSelector((state) => state.firestoreReducer.data.loyaltyUser);
  const loyaltyRewards = useSelector(
    (state) => state.firestoreReducer.ordered.loyaltyRewards,
  );
  const [currentData, setCurrentData] = useState(null);
  const [currentReward, setCurrentReward] = useState(null);
  const [activeDescription, setActiveDescription] = useState(null);
  const [rewardConfirmed, setRewardConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!DATA) {
      const data = find(DATA, { value: "home" });
      setCurrentData(data);
    }
  }, [DATA]);

  const changeData = (destination, rewardId) => {
    if (!!rewardId && loyaltyRewards) {
      const nextReward = find(loyaltyRewards, { id: rewardId });
      setCurrentReward(nextReward);
    } else {
      setCurrentReward(null);
    }
    const nextData = find(DATA, { value: destination });
    setCurrentData(nextData);
    setActiveDescription(null);
    setRewardConfirmed(false);
  };

  const handleClose = () => {
    closeModal();
    setTimeout(() => {
      changeData("home");
      setActiveDescription(null);
      setRewardConfirmed(false);
    }, 800);
  };

  const copyToClipboard = async (e, text) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(text).then(() => {
        message.open({
          type: "success",
          content: t("clipboard_success"),
        });
      });
    } catch (error) {
      message.open({
        type: "error",
        content: t("clipboard_error"),
      });
    }
  };

  const handleDescriptionActivation = (index) => {
    if (index === activeDescription) {
      setActiveDescription(null);
    } else setActiveDescription(index);
  };

  const handleRewardClaim = () => {
    setIsLoading(true);
    saveLoyaltyClaim()
      .then(async () => {
        await updateUserData();
        setIsLoading(false);
        setRewardConfirmed(true);
      })
      .catch((err) => {
        console.log(err);
        notification["warning"]({
          message: t("errors.generic"),
        });
        setIsLoading(false);
        sendExceptionToSlack(err);
      });
  };

  const saveLoyaltyClaim = async () => {
    return new Promise((resolve, reject) => {
      let timestamp = dayjs().unix();
      let object = {
        dateTime: timestamp,
        points: currentReward.points,
        rewardId: currentReward.id,
        rewardType: currentReward.type,
        userId: auth.uid,
        cashedIn: false,
        quantity: currentReward.quantity || null,
      };
      if (currentReward.discountFields) {
        object.discountFields = currentReward.discountFields;
      }
      firestore
        .collection(FIRESTORE_LOYALTY_CLAIMS_TABLE)
        .add(object)
        .then(function (docRef) {
          resolve(docRef.id);
        })
        .catch((err) => reject(err.message));
    });
  };

  const updateUserData = async () => {
    const userDocRef = firestore
      .collection(FIRESTORE_LOYALTY_USERS_TABLE)
      .doc(auth.uid);

    try {
      return await firestore.runTransaction(async (transaction) => {
        const userDoc = await transaction.get(userDocRef);

        const newCurrentPoints =
          userDoc.data().currentPoints - currentReward.points;
        const newTotalPointsSpent =
          userDoc.data().totalPointsSpent + currentReward.points;
        transaction.update(userDocRef, {
          currentPoints: newCurrentPoints,
          totalPointsSpent: newTotalPointsSpent,
        });
      });
    } catch (err) {
      console.error(err);
      sendExceptionToSlack("LoyaltyProgramModal - updateUserData: ", err);
    }
  };

  return (
    <ModalWrapper
      initial={"hidden"}
      animate={active ? "visible" : "hidden"}
      variants={modalVariants}
      transition={{ bounce: 0 }}
    >
      <ModalHeader>
        {!!currentData && (
          <>
            {!!currentData.back_destination ? (
              <button
                onClick={() => changeData(currentData.back_destination)}
                style={{ padding: 0 }}
              >
                <img
                  className={"arrow-icon"}
                  src={"/assets/img/loyalty-program/left-arrow.svg"}
                  alt={""}
                />
              </button>
            ) : (
              <div style={{ width: 16 }} />
            )}
            <h2>{currentData.title}</h2>
          </>
        )}
        <button className={"close-button"} onClick={handleClose}>
          <CloseOutlined style={{ fontSize: 16, color: "#000" }} />
        </button>
      </ModalHeader>
      {!!currentData && (
        <ModalBody rewardConfirmed={rewardConfirmed}>
          {currentData.columns ? (
            <div className={"columns-container items-container"}>
              {currentData.columns.map((column, index) => (
                <ModalColumn key={index}>
                  <h3>{column.title}</h3>
                  <p>
                    {column.description_authenticated
                      ? user
                        ? column.description_authenticated
                        : column.description_unauthenticated
                      : column.description}
                  </p>
                  {!!column.buttons && (
                    <div className={"button-container"}>
                      {column.buttons.map((button, buttonIndex) => (
                        <button
                          key={buttonIndex}
                          className={"column-button"}
                          onClick={() => changeData(button.destination)}
                        >
                          <img src={button.icon} alt={""} />
                          {button.title}
                          <img
                            className={"arrow-icon"}
                            src={"/assets/img/loyalty-program/right-arrow.svg"}
                            alt={""}
                          />
                        </button>
                      ))}
                    </div>
                  )}
                  {!!column.invitation_link && (
                    <div className={"link-wrapper"}>
                      <div className={"link-container"}>
                        <p>{column.invitation_link}</p>
                      </div>
                      <button
                        title={t("copy_to_clipboard")}
                        className={"clipboard-button"}
                        onClick={(e) =>
                          copyToClipboard(e, column.invitation_link)
                        }
                      >
                        <img
                          src={"/assets/img/loyalty-program/link.svg"}
                          alt={""}
                        />
                      </button>
                    </div>
                  )}
                </ModalColumn>
              ))}
            </div>
          ) : currentData.value === "claim_reward" ? (
            !!currentReward &&
            (rewardConfirmed ? (
              <>
                <GiftIcon
                  size={164}
                  style={{
                    boxShadow: "0 9px 28px 8px rgba(0, 0, 0, 0.15)",
                  }}
                />
                <div className={"confirmation-container"}>
                  <img
                    src={currentReward.icon}
                    alt={""}
                    className={"item-icon"}
                  />
                  <div>
                    <h3>{currentReward.title}</h3>
                    <p className={"description"}>
                      {currentReward.description} {t("points")}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <ModalItem className={"reward-detail-container"}>
                <div className={"content-wrapper"}>
                  <img
                    src={currentReward.icon}
                    alt={""}
                    className={"item-icon"}
                  />
                  <div>
                    <h3>{currentReward.title}</h3>
                    <p className={"points"}>
                      {currentReward.points} {t("points")}
                    </p>
                  </div>
                  {!!user && currentReward.points <= user.currentPoints && (
                    <GiftIcon size={28} style={{ marginLeft: "auto" }} />
                  )}
                </div>
                <div
                  className={"description-container"}
                  style={{ display: "block" }}
                >
                  <p>{currentReward.description}</p>
                </div>
                {!!user && currentReward.points <= user.currentPoints ? (
                  <Popconfirm
                    title={t("are_you_sure")}
                    description={t("reward_confirmation")}
                    placement={"bottomLeft"}
                    okText={t("yes")}
                    cancelText={t("no")}
                    onConfirm={handleRewardClaim}
                  >
                    <Button
                      className={"collect-button"}
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      {t("confirm")}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Tooltip
                    title={t(!!user ? "not_enough_points" : "create_account")}
                    placement={"top"}
                    overlayInnerStyle={{ fontSize: 11 }}
                  >
                    <button disabled className={"collect-button"}>
                      {t("confirm")}
                    </button>
                  </Tooltip>
                )}
              </ModalItem>
            ))
          ) : (
            <div className={"items-container"}>
              {currentData.value === "rewards_overview"
                ? !!loyaltyRewards &&
                  loyaltyRewards.map((item) => (
                    <ModalItem
                      key={item.id}
                      as={"button"}
                      className={"button-item"}
                      onClick={() => changeData("claim_reward", item.id)}
                    >
                      <img src={item.icon} alt={""} className={"item-icon"} />
                      <div>
                        <h3>{item.title}</h3>
                        <p className={"points"}>
                          {item.points} {t("points")}
                        </p>
                      </div>
                      {!!user && item.points <= user.currentPoints && (
                        <GiftIcon size={28} style={{ marginLeft: "auto" }} />
                      )}
                    </ModalItem>
                  ))
                : currentData.items.map((item, index) => (
                    <ModalItem
                      key={index}
                      as={"button"}
                      className={`${
                        (item.value === "signupToNewsletter" ||
                          item.value === "createAccount") &&
                        !!user
                          ? "checked"
                          : ""
                      } ${index === activeDescription ? "active" : ""}`}
                      onClick={() => handleDescriptionActivation(index)}
                      disabled={
                        (item.value === "signupToNewsletter" ||
                          item.value === "createAccount") &&
                        !!user
                      }
                    >
                      <div className={"content-wrapper"}>
                        {!!item.icon && (
                          <img
                            src={item.icon}
                            alt={""}
                            className={"item-icon"}
                          />
                        )}
                        <div>
                          <h3>{item.title}</h3>
                          <p className={"points"}>
                            {typeof item.points === "number" ? (
                              <>
                                {item.points} {t("points")}
                              </>
                            ) : (
                              item.points
                            )}
                          </p>
                        </div>
                        {(item.value === "signupToNewsletter" ||
                          item.value === "createAccount") &&
                        !!user ? (
                          <img
                            src={"/assets/img/loyalty-program/check.svg"}
                            alt={""}
                            className={"check-icon"}
                          />
                        ) : (
                          <img
                            className={"arrow-icon"}
                            src={"/assets/img/loyalty-program/down-arrow.svg"}
                            alt={""}
                          />
                        )}
                      </div>
                      <div className={"description-container"}>
                        <p>{item.description}</p>
                      </div>
                    </ModalItem>
                  ))}
            </div>
          )}
        </ModalBody>
      )}
      <ModalFooter>
        {!!user && (
          <div className={"points-container"}>
            <span className={"points-number"}>{user.currentPoints}</span>{" "}
            <span className={"points-text"}>{t("points")}</span>
          </div>
        )}
        <div className={"button-container"}>
          <button onClick={() => changeData("faq")} style={{ padding: 0 }}>
            <img src={"/assets/img/loyalty-program/info.svg"} alt={""} />
          </button>
          {!!currentData && currentData.value !== "claim_reward" && (
            <Button
              className={"collect-button"}
              onClick={() => changeData("rewards_overview")}
            >
              {t("collect_rewards")}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(motion.div)`
  --padding: 12px;
  --border-radius-lg: 12px;
  --border-radius-md: 8px;
  --border-radius-sm: 4px;

  display: grid;
  grid-template-rows: 51px auto 68px;
  width: 320px;
  height: 500px;
  position: fixed;
  bottom: 40px;
  right: 24px;
  background-color: #ffffff;
  border-radius: var(--border-radius-lg);
  z-index: 100;
  overflow: hidden;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.16),
    0 3px 6px -4px rgba(0, 0, 0, 0.24),
    0 9px 28px 8px rgba(0, 0, 0, 0.1);

  button {
    background: inherit;
    border: none;
  }

  .collect-button {
    color: #ffffff;
    background-color: ${colors.loyalty_primary};
    font-size: 11px;
    padding: 6px 18px;
    border-radius: var(--border-radius-sm);
    width: fit-content;
    margin-left: auto;

    &:not(:disabled):hover {
      color: #ffffff;

      background-color: ${colors.loyalty_primary};
      opacity: 0.8;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #dcdcdc;
    }
  }

  h2,
  h3,
  p {
    margin: 0;
  }

  img {
    width: 16px;
  }

  .arrow-icon {
    width: 13px;
  }

  & ::selection {
    color: #ffffff;
    background: ${colors.loyalty_primary};
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--padding) * 1.25) var(--padding) 0;

  h2 {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .close-button {
    padding: 0;
  }
`;

const ModalBody = styled.div`
  overflow-y: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;

  ${({ rewardConfirmed }) => {
    switch (rewardConfirmed) {
      case true:
        return css`
          display: grid;
          place-content: center;
          justify-items: center;
          gap: 1.5rem;
          background-image: linear-gradient(
              rgba(255, 255, 255, 0.75),
              rgba(255, 255, 255, 0.75)
            ),
            url("/assets/img/loyalty-program/confetti.png");
          background-size: cover;
          background-position: center;
        `;
      default:
        return css`
          padding: calc(var(--padding) * 1.25) var(--padding);
        `;
    }
  }}
  &::-webkit-scrollbar {
    display: none;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
  }

  .columns-container {
    gap: calc(var(--padding) * 1.25);
  }

  .confirmation-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    max-width: 75%;

    h3 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    p {
      font-size: 12px;
      line-height: 1.35;
    }

    .item-icon {
      width: 36px;
    }
  }
`;

const ModalColumn = styled.div`
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.16);
  border-radius: var(--border-radius-md);
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.35;
  }

  p {
    font-size: 13px;
  }

  .button-container {
    padding-inline: calc(var(--padding) * 0.5);
  }

  .column-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: var(--padding);
    padding: calc(var(--padding)) 0;
    border-radius: 0;
    color: #000;

    .arrow-icon {
      margin-left: auto;
    }

    &:not(:last-of-type) {
      border-bottom: 2px solid #dcdcdc;
    }

    &:hover {
      .arrow-icon {
        transform: translateX(15%);
      }
    }
  }

  .link-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.loyalty_primary};
    border-radius: var(--border-radius-sm);
    gap: 4px;

    .link-container {
      overflow: scroll;
      padding: 2px 4px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
      }

      p {
        line-height: 1;
      }
    }

    .clipboard-button {
      background-color: ${colors.loyalty_primary};
      padding: 4px 8px;
      border-radius: 0;

      &:not(:disabled):hover {
        opacity: 0.8;
      }
    }
  }
`;

const ModalItem = styled(ModalColumn)`
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.16);

  &:not(:disabled, .reward-detail-container):hover {
    background-color: #eaeaea;
  }

  &:disabled {
    cursor: initial;
  }

  &.reward-detail-container {
    gap: var(--padding);
  }

  &.button-item {
    flex-direction: row;
    align-items: center;
    gap: var(--padding);
  }

  &.checked {
    box-shadow: none;
    border: 1px solid #dcdcdc;
    order: 1;

    h3,
    p {
      opacity: 0.5;
    }
  }

  & > div {
    text-align: start;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--padding);
    width: 100%;
  }

  h3,
  p {
    margin: 0;
    line-height: 1.35;
    text-wrap: pretty;
  }

  h3 {
    font-size: 12px;
    font-weight: initial;
  }

  p {
    font-size: 11px;

    &.points {
      font-weight: 700;
    }
  }

  .item-icon {
    width: 18px;
  }

  .check-icon,
  .arrow-icon {
    margin-left: auto;
  }

  .arrow-icon {
    transition: transform 0.3s ease-out;
  }

  .description-container {
    display: none;
  }

  &.active {
    &:hover {
      background-color: #ffffff;
    }

    .description-container {
      display: block;
    }

    .arrow-icon {
      transform: rotate(180deg);
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  padding: calc(var(--padding) * 1.25) var(--padding);
  border-top: 2px solid #dcdcdc;
  max-height: fit-content;

  .points-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    span {
      display: block;
      font-weight: 500;
      line-height: 1;

      &.points-number {
        font-size: 1.25rem;
      }

      &.points-text {
        font-size: 11px;
      }
    }
  }

  .button-container {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }
`;
