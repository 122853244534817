import { DELETE_FILTERS, SAVE_FILTERS } from "../actions/filterActions";

const initState = [];

const filterReducer = (state = initState, action) => {
  const filters = state,
    filter = action.payload;

  if (action.type === SAVE_FILTERS) {
    return { ...filters, [filter.filterType]: filter.filterValue };
  }

  if (action.type === DELETE_FILTERS) {
    return null;
  }

  return filters;
};

export default filterReducer;
