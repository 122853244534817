import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Breadcrumb } from "antd";
import { device } from "../utils/breakpoints";
import { Swiper } from "swiper/react";
import { colors } from "../utils/theme";

export const StyledBreadcrumbs = ({ items }) => (
  <Container>
    <Breadcrumbs items={items} />
  </Container>
);

const Breadcrumbs = styled(Breadcrumb)`
  padding-top: 12px;
  margin-bottom: 0;
  @media ${device.md} {
    padding-top: 25px;
    padding-bottom: 1.5rem;
  }
`;

export const SliderWrapper = styled(Swiper)`
  width: 100%;

  @media ${device.lg} {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .swiper-pagination {
    margin-top: 2rem;
  }

  .swiper-pagination-bullets {
    bottom: -2px;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.primary};
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    top: 50%;

    &:hover {
      &:after {
        box-shadow: 0 0 6px #0000004d;
      }
    }
  }

  .swiper-button-next {
    right: 4px;
  }

  .swiper-button-prev {
    left: 4px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 22px;
    padding: 8px 10px;
    border-radius: 2px;
    box-shadow: 0 0 4px #0000002e;
    transition: 0.2s all ease;
    background: ${colors.primary};
  }

  .swiper-button-disabled {
    display: none;
  }
`;

export const AuthCardWrapper = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
  padding: 1.5rem;

  @media ${device.md} {
    margin-top: 0;
    padding: 4rem;
  }
`;
