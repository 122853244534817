import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const FooterOpeningHours = ({ spaceBottomClass, spaceLeftClass, sideMenu, colorClass, widgetColorClass, data }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${spaceBottomClass ? spaceBottomClass : ""} ${
        sideMenu ? "ml-ntv5" : spaceLeftClass ? spaceLeftClass : ""
      } ${widgetColorClass ? widgetColorClass : ""}`}
    >
      <div className="footer-title">
        <h3>{t("opening_hours")}</h3>
      </div>
      <div className={`footer-list ${colorClass ? colorClass : ""}`}>
        <ul>
          {data.map((item) => {
            return (
              <li key={item.id} style={{ margin: 0 }}>
                <span>
                  {item.title_short}
                  {" - "}
                  {item.value}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

FooterOpeningHours.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string,
};

export default FooterOpeningHours;
