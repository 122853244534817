import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  checkMinAmount,
  getDiscountPrice,
  getProductCartQuantity,
} from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import urls from "../../urls";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../helpers/formatters";
import { CURRENCY } from "../../constants/DeliveryConstants";
import { PRODUCT_PLACEHOLDER_SRC } from "../../constants/ProductConstants";
import styled from "styled-components";
import LazyImage from "../image/LazyImage";
import { device } from "../../utils/breakpoints";
import { SHOP_LIST_PRODUCT_IMAGE } from "../../constants/ImageConstants";
import { colors } from "../../utils/theme";
import { STORAGE_LAST_CLICKED_PRODUCT } from "../../constants/LocalStorageConstants";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popover, Select } from "antd";
import CartIncreaser from "../cart/CartIncreaser";
import { setProductAnalyticsEvent } from "../../helpers/analytics";
import { ANALYTICS_ADD_TO_CART } from "../../constants/AnalyticsConstants";
import { transparentize } from "../../utils/helperFn";
import { Col } from "react-bootstrap";

const { Option } = Select;

const ProductGridShop = ({
  product,
  addToCart,
  cartItems,
  spaceBottomClass,
  isFeatured,
}) => {
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const minAmount = product.min_amount || 1;
  const [quantityCount, setQuantityCount] = useState(minAmount);
  const [variation, setVariation] = useState(
    !!product.variations ? product.variations[0] : null
  );
  const [type, setType] = useState(!!product.type ? product.type[0] : null);
  const productCartQty = getProductCartQuantity(cartItems, product);
  const [chosenWeight, setChosenWeight] = useState(
    !!product.variations ? product.variations[0].weight : null
  );

  const setLastClickedStorageItem = () => {
    localStorage.setItem(STORAGE_LAST_CLICKED_PRODUCT, product.id);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const handlePopoverVisibleChange = (newVisible) => {
    setPopoverVisible(newVisible);
  };

  const handleAddToCart = () => {
    addToCart(product, quantityCount, variation, type);
    setQuantityCount(minAmount);
    setProductAnalyticsEvent(ANALYTICS_ADD_TO_CART, product, quantityCount);
    hidePopover();
  };

  const handleWeightChange = (variation) => {
    setChosenWeight(variation.weight);
    setVariation(variation);
  };

  return (
    <Fragment>
      <Col
        className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        xs={6}
        xl={4}
        id={product.id}
      >
        <div className="product-img">
          <Link
            to={urls.product + product.url}
            onClick={setLastClickedStorageItem}
          >
            <Image
              className="default-img"
              src={
                !!product.image && !!product.image[0]
                  ? product.image[0]
                  : PRODUCT_PLACEHOLDER_SRC
              }
              alt={product.name}
            />
          </Link>
          {!!product.discount && (
            <div className="product-img-badges">
              <span className="pink">-{product.discount}%</span>
            </div>
          )}
          {!product.stock && (
            <OutOfStockBanner>{t("out_of_stock")}</OutOfStockBanner>
          )}

          {isFeatured && (
            <FeaturedTag>
              <span>{t("featured_tag")}</span>
            </FeaturedTag>
          )}
        </div>
        <div className="product-content text-center mt-2">
          <div className={"d-flex justify-content-between"} style={{ gap: 2 }}>
            <div className={"d-block"}>
              <h3>
                <Link
                  to={urls.product + product.url}
                  onClick={setLastClickedStorageItem}
                  style={{
                    color: "#000",
                    fontWeight: 500,
                    textWrap: "pretty",
                    hyphens: "auto",
                  }}
                >
                  {product.name}
                </Link>
              </h3>
              {product.shortDescription && (
                <ShortDescription>{product.shortDescription}</ShortDescription>
              )}
              {product.rating && product.rating > 0 && (
                <div className="product-rating">
                  <Rating ratingValue={product.rating} />
                </div>
              )}
              <div className="product-price">
                {discountedPrice !== null ? (
                  <Fragment>
                    <div className={"d-block"}>
                      <span className="advisory-text">
                        {t("suggested_price")}
                      </span>
                      <span className="old">
                        {CURRENCY + formatPrice(product.price)}
                      </span>
                    </div>
                    <span>{CURRENCY + formatPrice(discountedPrice)}</span>{" "}
                  </Fragment>
                ) : (
                  <span>{CURRENCY + formatPrice(product.price)}</span>
                )}
              </div>
            </div>
            {product.stock > 0 && productCartQty < product.stock ? (
              <Popover
                content={
                  <>
                    {!!product.type && (
                      <TypeWrapper>
                        <Select
                          className="w-100"
                          value={type}
                          onChange={(value) => setType(value)}
                        >
                          {product.type.map((type, index) => {
                            return (
                              <Option value={type} key={index}>
                                {type}
                              </Option>
                            );
                          })}
                          ;
                        </Select>
                      </TypeWrapper>
                    )}
                    {!!product.variations && (
                      <WeightWrapper>
                        {product.variations.map((item, index) => {
                          return (
                            <Weight
                              key={index}
                              active={chosenWeight === item.weight}
                              onClick={() => handleWeightChange(item)}
                            >
                              {item.weight} {t("gram_unit")}
                            </Weight>
                          );
                        })}
                      </WeightWrapper>
                    )}
                    <div
                      className={
                        "d-flex align-items-end justify-content-between"
                      }
                    >
                      <CartIncreaser
                        cartItem={product}
                        amount={quantityCount}
                        onPressDecrease={() =>
                          setQuantityCount(
                            checkMinAmount(minAmount, quantityCount)
                          )
                        }
                        onPressIncrease={() =>
                          setQuantityCount(
                            quantityCount < product.stock - productCartQty
                              ? quantityCount + 1
                              : quantityCount
                          )
                        }
                      />
                      <AddToCart onClick={handleAddToCart} type={"primary"}>
                        {<CheckOutlined />}
                      </AddToCart>
                    </div>
                  </>
                }
                title={product.name}
                trigger="click"
                visible={popoverVisible}
                onVisibleChange={handlePopoverVisibleChange}
              >
                <PlusButton>
                  <PlusOutlined style={{ color: "#ffffff", fontSize: 20 }} />
                </PlusButton>
              </Popover>
            ) : (
              <OutOfStockButton>
                <CloseOutlined style={{ color: "#ffffff", fontSize: 20 }} />
              </OutOfStockButton>
            )}
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

const ShortDescription = styled.h4`
  font-size: 14px;
  font-weight: 300;
  color: #000;
  text-align: left;
`;

const TypeWrapper = styled.div`
  display: block;
  margin-bottom: 0.8rem;
`;

const Weight = styled.div`
  padding: 5px 10px;
  background-color: ${({ active }) => (active ? colors.cta_btn : "#eceff8")};
  color: ${({ active }) => (active ? colors.cta_btn_text : colors.text)};
  cursor: pointer;
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const WeightWrapper = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
  max-width: 200px;
  flex-wrap: wrap;
`;

const AddToCart = styled(Button)`
  margin-left: 12px;
`;

const OutOfStockButton = styled.div`
  display: flex;
  border-radius: 100%;
  background-color: ${transparentize(colors.primary, 0.6)};
  height: fit-content;
  padding: 11px;
  margin-top: 4px;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 8%);
  cursor: not-allowed;
`;

const PlusButton = styled.div`
  display: flex;
  border-radius: 100%;
  background-color: ${colors.primary};
  height: fit-content;
  padding: 9px;
  margin-top: 4px;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 8%);
  cursor: pointer;

  @media ${device.md} {
    padding: 11px;
  }
`;

const OutOfStockBanner = styled.div`
  background-color: #d5a200db;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  padding: 6px;
  border-radius: 4px;
`;

const FeaturedTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.featuredTag};
  padding: 0 8px;
  color: #fff;
  border-bottom-right-radius: 3px;
`;

const Image = styled(LazyImage)`
  min-height: ${SHOP_LIST_PRODUCT_IMAGE.sm.height + "px"};
  @media ${device.md} {
    min-height: ${SHOP_LIST_PRODUCT_IMAGE.md.height + "px"};
  }
  @media ${device.lg} {
    min-height: ${SHOP_LIST_PRODUCT_IMAGE.lg.height + "px"};
  }
  @media ${device.xl} {
    min-height: ${SHOP_LIST_PRODUCT_IMAGE.xl.height + "px"};
  }
`;

ProductGridShop.propTypes = {
  addToCart: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
};

export default ProductGridShop;
