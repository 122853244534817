import LoyaltyProgramFab from "./LoyaltyProgramFab";
import LoyaltyProgramModal from "./LoyaltyProgramModal";
import {
  closeLoyaltyProgramModal,
  openLoyaltyProgramModal,
} from "../../redux/actions/loyaltyModalActions";
import { useDispatch, useSelector } from "react-redux";

const LoyaltyProgramModule = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.loyaltyModalData);

  return (
    <>
      <LoyaltyProgramModal
        closeModal={() => dispatch(closeLoyaltyProgramModal())}
        active={modalState}
      />
      <LoyaltyProgramFab
        onClick={() => dispatch(openLoyaltyProgramModal())}
        active={!modalState}
      />
    </>
  );
};

export default LoyaltyProgramModule;
