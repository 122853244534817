// ** TITLES ** //
export const META_HOME_TITLE = "(H)eerlijk en puur kwaliteitsvlees";
export const META_ABOUT_TITLE = "Over Ons";
export const META_CHECKOUT_TITLE = "Afrekenen";
export const META_COMPARE_TITLE = "Vergelijken";
export const META_CONTACT_TITLE = "Contact";
export const META_CART_TITLE = "Winkelwagen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_REGISTER_TITLE = "Inloggen - Registreren";
export const META_MY_ACCOUNT_TITLE = "Mijn Account";
export const META_ORDERS_TITLE = "Bestellingen";
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_ORDER_CONFIRMED_TITLE = "Bestelling Bevestigd";
export const META_ORDER_FAILED_TITLE = "Betaling Mislukt";
export const META_WISHLIST_TITLE = "Favorieten";
export const META_BLOG_TITLE = "Blog";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_DELIVERY_RETURNS_TITLE = "Verzenden & retourneren";
export const META_FAQ_TITLE = "Veelgestelde Vragen";
export const META_UNSUBSCRIBE_MAILS_TITLE = "Uitschrijven voor e-mails";

// Landing pages
export const META_HALAL_SLAGER_TITLE = "Dé Halal Slager van Nederland";
export const META_HALAL_CERTIFICATEN_TITLE = "Halal Certificaten";
export const META_RECIPES_TITLE =
  "Recepten met Halal Vlees";


// ** DESCRIPTIONS ** //
export const META_GENERIC_DESCRIPTION =
  "Met Halalvlees Online heb je jouw halal vleesproducten de volgende dag in huis. Puur en vers vlees van de beste kwaliteit.";
export const META_PRODUCT_DESCRIPTION =
  "bij Halalvlees Online. 100% Halal vlees. Voor 23.59u besteld? Morgen bezorgd.";
export const META_ABOUT_DESCRIPTION =
  "Wij zijn een online slagerij met een duidelijke missie: (h)eerlijk en ambachtelijk vlees leveren voor de beste prijs.";
export const META_CONTACT_DESCRIPTION =
  "Heb je vragen of opmerkingen over Halalvlees Online? Vul ons contactformulier in en wij nemen direct contact met je op!";
export const META_RESET_PASSWORD_DESCRIPTION =
  "Je wachtwoord vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_REGISTER_DESCRIPTION =
  "Log in bij Halalvlees Online om al je bestellingen in te zien of om een recente bestelling opnieuw te plaatsen!";
export const META_ORDERS_DESCRIPTION =
  "Al jouw Halalvlees Online bestellingen op een rij. Hier kun je ze inzien of opnieuw bestellen!";
export const META_NOT_FOUND_DESCRIPTION =
  "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat.";
export const META_UNSUBSCRIBE_MAILS_DESCRIPTION =
  "Geen marketing e-mails meer ontvangen? Schrijf je hier uit.";

// Landing pages
export const META_HALAL_SLAGER_DESCRIPTION =
  "Wij zijn dé online Halal slager van Nederland. Bestel vandaag je favoriete stukje vlees en krijg het morgen vers thuis bezorgd. Eet smakelijk!";
export const META_HALAL_CERTIFICATEN_DESCRIPTION =
  "Ons vlees is 100% Halal. Bekijk hier onze Halal certificaten.";
export const META_RECIPES_DESCRIPTION =
  "Een heerlijk gerecht met halal vlees op tafel zetten? Wij hebben de lekkerste recepten voor je verzameld! Bekijk ze hier.";

export const META_SHOP_SLOGAN = "Overheerlijk Halal Vlees";
export const META_CATEGORY_SLOGAN = "Premium Halal Vlees";
