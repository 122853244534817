import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import { SOCIAL_MEDIA } from "../../constants/CompanyConstants";
import FooterOpeningHours from "../../components/footer/FooterOpeningHours";
import openingHoursNL from "../../data/company/opening_hours.json";
import openingHoursEN from "../../data/company/opening_hours_en.json";
import i18n from "../../translations/i18n";
import { LANGUAGE_NL } from "../../constants/LanguageConstants";
import FooterBottom from "../../components/footer/FooterBottom";
import { useSelector } from "react-redux";
import { formatNameToURL } from "../../helpers/formatters";
import { orderBy } from "lodash";

const FooterWrapper = ({ containerClass }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [openingHoursData, setOpeningHoursData] = useState(openingHoursNL);
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );
  const [popularProducts, setPopularProducts] = useState([]);
  const firstColumnProducts = popularProducts.slice(0, 8);
  const secondColumnProducts = popularProducts.slice(8);

  useEffect(() => {
    // Calculate most used categories
    const filteredProducts = products.filter((p) => !!p.purchased);
    const mostOrderedProducts = orderBy(
      filteredProducts,
      ["purchased"],
      ["desc"]
    );
    setPopularProducts(mostOrderedProducts.slice(0, 16));

    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setOpeningHoursData(
      i18n.language === LANGUAGE_NL ? openingHoursNL : openingHoursEN
    );
  }, [i18n.language]);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className={"footer-area pt-70"}>
      <div className={`${containerClass ? containerClass : "container-fluid"}`}>
        <div className="row mb-30" style={{ gap: "12px" }}>
          <div className={"col-lg-3 col-sm-12"}>
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo-liggend.png"
              spaceBottomClass="mb-30"
            />
          </div>
          <div className={"col-lg-2 col-sm-12"}>
            <div className="footer-widget">
              <div className="footer-title">
                <h3>{t("products")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  {firstColumnProducts.length > 0 &&
                    firstColumnProducts.map((product, key) => {
                      return (
                        <li key={key}>
                          <Link
                            to={urls.product + formatNameToURL(product.url)}
                          >
                            {product.name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 col-sm-12"}>
            <div className="footer-widget">
              <div className="footer-title">
                <h3>{t("products")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  {secondColumnProducts.length > 0 &&
                    secondColumnProducts.map((product, key) => {
                      return (
                        <li key={key}>
                          <Link
                            to={urls.product + formatNameToURL(product.url)}
                          >
                            {product.name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 col-sm-12"}>
            <div className={"footer-widget"}>
              <div className="footer-title">
                <h3>{t("customer_service")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={urls.about}>{t("about_us")}</Link>
                  </li>
                  <li>
                    <Link to={urls.shop}>{t("products")}</Link>
                  </li>
                  <li>
                    <Link to={urls.recipes}>{t("recipes")}</Link>
                  </li>
                  <li>
                    <Link to={urls.contact}>{t("contact")}</Link>
                  </li>
                  <li>
                    <Link to={urls.delivery_returns}>{t("returns")}</Link>
                  </li>
                  <li>
                    <Link to={urls.packaging}>{t("packaging")}</Link>
                  </li>
                  <li>
                    <Link to={urls.faq}>{t("faq")}</Link>
                  </li>
                  <li>
                    <Link to={urls.certificates}>
                      {t("certificates_title")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 col-sm-12"}>
            <div className={"footer-widget"}>
              <FooterOpeningHours data={openingHoursData} />
              <div className="footer-list">
                <ul>
                  {SOCIAL_MEDIA.length > 0 && (
                    <div className="off-canvas-widget-social mt-3">
                      {SOCIAL_MEDIA.map((item, key) => {
                        return (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"pl-2 pr-2"}
                            key={key}
                          >
                            <i
                              className={item.icon}
                              style={{ color: "white" }}
                            />
                          </a>
                        );
                      })}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-4 col-sm-6"} />
          <div className="payment-methods-container">
            <img
              src="/assets/img/payment/payment_methods.png"
              alt={t("payment_method")}
            />
          </div>
          <FooterBottom />
        </div>
      </div>
      {/*<button*/}
      {/*  className={`scroll-top bg-shadow ${scroll > top ? "show" : ""}`}*/}
      {/*  onClick={() => scrollToTop()}*/}
      {/*>*/}
      {/*  <i className="fa fa-angle-double-up" />*/}
      {/*</button>*/}
    </footer>
  );
};

FooterWrapper.propTypes = {
  containerClass: PropTypes.string,
};

export default FooterWrapper;
