import React from "react";
import footerImg from "../../assets/img/footertop-dieren.png";

const PreFooter = () => {
  return (
    <div className="text-center mt-100">
      <img src={footerImg} className="w-150"/>
    </div>
  );
};

export default PreFooter;
