export const DESCRIPTION_LENGTH = 150;
export const CATEGORY = "category";
export const SEARCH = "search";
export const PAGINATION = "pagination";
export const PRICEFILTER = "priceFilter";
export const DEFAULT = "default";
export const PRODUCT_PLACEHOLDER_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Fplaceholder.jpg?alt=media";
export const PRODUCT_PLACEHOLDER_LOCAL_SRC =
  "/assets/img/product/placeholder.webp";
export const CATEGORY_PLACEHOLDER_SRC =
  "/assets/img/categories/placeholder.jpg";
export const RELATED_PRODUCTS_IMG_WIDTH = 325;
